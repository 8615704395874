import { useState, useEffect, useRef } from "react";
import ChatService from "../../front-services/ChatService";
import ChatCache from "../../utils/ChatCache";
import { ChatHeader } from "./shared/ChatHeader";
import { ChatMessages } from "./shared/ChatMessages";
import { ChatInput } from "./shared/ChatInput";
import { ChatButton } from "./shared/ChatButton";
import { Slider } from "../../widgets/Chat/components/slider/Slider";
import WideImageViewer from "./components/WideImageViewer";
import HandoffForm from "./components/Handoff/HandoffForm";
import constants from "../../utils/Constants";
import Utility from "./logic/Utility";
import MessageHandler from "./logic/MessageHandler";
import { KeywordHandler } from "./components/slider/handlers/KeywordHander";

const chatService = new ChatService();
const messageHandler = new MessageHandler();
const chatCache = new ChatCache();

interface ChatProps {
  startOpen?: boolean;
  isMobile?: boolean;
  isEditor?: boolean;
  editorStyle?: any;
  assistant: any;
  language: string;
}

interface AssistantChatConfiguration {
  loaded: boolean;
  isStreamEnabled?: boolean;
}

export const Chat = ({
  startOpen = false,
  isMobile = false,
  isEditor = false,
  editorStyle = null,
  assistant,
  language,
}: ChatProps) => {
  const [isOpen, setIsOpen] = useState(isEditor || startOpen);
  const [messages, setMessages] = useState<any[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [toolName, setToolName] = useState<string | null>(null);
  const [welcomeMessageTime] = useState(Utility.getTime());
  const [showHandoffButton, setShowHandoffButton] = useState(true);
  const [keywords, setKeywords] = useState<string[]>([]);

  const [fullScreen, setFullScreen] = useState({
    isWIVFullScreen: false,
    WIVfullScreenImage: null,
    isHandoffFormFullScreen: false,
  });

  const [sliderContext, setSliderContext] = useState<{
    isVisible: boolean;
    template: React.ReactNode;
    selectedKeyword: string | null;
  }>({
    isVisible: false,
    template: null,
    selectedKeyword: null,
  });

  const [ready, setReady] = useState(false);
  const [style, setStyle] = useState({
    color: editorStyle?.mainColor || "#a72eff",
    name: "Nome Assistente",
    fileUrl: "https://plutoniosolutions.com/assets/img/SmallCircle.webp",
    loaded: false,
    ...editorStyle,
  });
  const [assistantChatConfiguration, setAssistantChatConfiguration] =
    useState<AssistantChatConfiguration>({
      loaded: false,
    });

  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const isAnyFullScreen =
    fullScreen.isWIVFullScreen || fullScreen.isHandoffFormFullScreen;

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTo(0, chatMessagesRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    if (assistant.id) {
      setMessages(chatCache.getMessages(assistant.id));
      setThreadId(chatCache.getThreadId(assistant.id));
      Promise.all([
        chatService
          .getAssistantChatConfiguration(assistant.id, language)
          .then((response) => {
            if (response.ok) return response.json();
          }),
        chatService.getWidgetConfiguration(assistant.id).then((response) => {
          if (response.ok) return response.json();
        }),
      ]).then(([configData, widgetData]) => {
        if (configData)
          setAssistantChatConfiguration({ ...configData, loaded: true });
        if (widgetData) {
          const resStyle = widgetData.configuration.style;
          document.documentElement.style.setProperty(
            "--primary-color",
            resStyle.mainColor
          );
          setStyle({
            color: resStyle.mainColor || "#a72eff",
            name: resStyle.name || "Nome Assistente",
            fileUrl:
              resStyle.fileUrl ||
              "https://plutoniosolutions.com/assets/img/SmallCircle.webp",
            loaded: true,
          });
        }
      });
    }
  }, [assistant, language]);

  useEffect(() => {
    const handleWidgetMessage = (event: any) => {
      const message = event.detail;

      if (message.color) {
        setStyle(message);
        document.documentElement.style.setProperty(
          "--primary-color",
          message.color
        );
      }
    };

    const shadowHost = document.getElementById("widget-editor");
    if (shadowHost && shadowHost.shadowRoot) {
      shadowHost.shadowRoot.addEventListener(
        "widgetMessage",
        handleWidgetMessage
      );
    }

    return () => {
      if (shadowHost && shadowHost.shadowRoot) {
        shadowHost.shadowRoot.removeEventListener(
          "widgetMessage",
          handleWidgetMessage
        );
      }
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isMessageLoading]);

  useEffect(() => {
    chatService.ping().then((response) => {
      if (response.ok) setReady(true);
    });
  }, []);

  const toggleChatWidget = () => {
    setIsOpen((prev) => {
      if (prev) closeSlider();
      return !prev;
    });
  };

  const openSlider = (template: any) => {
    if (!constants.FEATURE_FLAGS.SLIDER) return;

    if (!template) return;

    setKeywords(KeywordHandler.returnOrderedKeywords(template, keywords));
    setSliderContext({
      isVisible: true,
      template: template,
      selectedKeyword: null,
    });
  };

  const closeSlider = () => {
    setSliderContext((prev) => ({ ...prev, isVisible: false }));
  };

  const handleUserMessage = async () => {
    if (!inputMessage.trim()) return;
    const userMessage = {
      type: "user",
      content: inputMessage,
      time: Utility.getTime(),
    };
    setMessages((prev) => [...prev, userMessage]);
    chatCache.addMessage(userMessage, threadId, assistant.id);
    setInputMessage("");
    setIsMessageLoading(true);

    const handler = assistantChatConfiguration.isStreamEnabled
      ? messageHandler.handleUserMessageWithStream
      : messageHandler.handleUserMessageWithoutStream;

    await handler(
      inputMessage,
      (newMessages: any) => {
        setMessages(newMessages);
        scrollToBottom();
      },
      setIsMessageLoading,
      setThreadId,
      assistant,
      threadId,
      setToolName,
      openSlider,
      language
    );
  };

  const handleShortcutClick = async (userMessage: string) => {
    const newMessage = {
      type: "user",
      content: userMessage,
      time: Utility.getTime(),
    };
    setMessages((prev) => [...prev, newMessage]);
    chatCache.addMessage(newMessage, threadId, assistant.id);
    setInputMessage("");
    setIsMessageLoading(true);

    const handler = assistantChatConfiguration.isStreamEnabled
      ? messageHandler.handleUserMessageWithStream
      : messageHandler.handleUserMessageWithoutStream;

    await handler(
      userMessage,
      (newMessages: any) => {
        setMessages(newMessages);
        scrollToBottom();
      },
      setIsMessageLoading,
      setThreadId,
      assistant,
      threadId,
      setToolName,
      openSlider,
      language
    );
  };

  const cleanCache = () => {
    setMessages([]);
    setThreadId(null);
    chatCache.remove(assistant.id);
  };

  const handleKeywordClick = (keyword: string) => {
    setSliderContext((prev) => ({
      ...prev,
      isVisible: true,
      selectedKeyword: keyword.toLowerCase(),
    }));
  };

  return (
    <>
      {!isEditor &&
        style.loaded &&
        ready &&
        assistantChatConfiguration.loaded && (
          <ChatButton
            isOpen={isOpen}
            toggleChatWidget={toggleChatWidget}
            style={style}
            isMobile={isMobile}
            language={language}
          />
        )}

      <Slider
        sliderContext={sliderContext}
        closeSlider={closeSlider}
        isMobile={isMobile}
        style={style}
      />

      {isMobile && sliderContext.isVisible && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "999",
          }}
          onClick={closeSlider}
        />
      )}

      <div
        ref={chatContainerRef}
        style={{
          position: isEditor ? "unset" : "fixed",
          right: isEditor ? "unset" : isMobile ? "0px" : "20px",
          width: isMobile
            ? constants.DIMENSIONS.MOBILE.OPEN.WIDTH_STRING
            : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING,
          bottom: isEditor ? "unset" : isMobile ? "0px" : "20px",
          height: isMobile
            ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
            : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING,
          maxHeight: isOpen
            ? isMobile
              ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
              : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING
            : "0",
          border: `1px solid ${style.color}`,
          boxShadow: `0 2px 6px -1px ${style.color}, 0 2px 2px -1px ${style.color}`,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          borderRadius: isMobile
            ? "0px"
            : sliderContext.isVisible
            ? "0px 30px 30px 0px"
            : "30px",
          overflow: "hidden",
          opacity: isOpen ? "1" : "0",
          transition:
            "max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, border 0.4s ease-in-out",
        }}
      >
        {/* LEAVE HERE AS IT IS USEFUL TO TEST SLIDER */}
        {/**<button onClick={() => openSlider(testTemplate)}>
              test slider with quote
            </button> */}
        {fullScreen.isWIVFullScreen && (
          <WideImageViewer
            image={fullScreen.WIVfullScreenImage}
            maxHeight={
              isMobile
                ? undefined
                : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING
            }
            maxWidth={
              isMobile
                ? undefined
                : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING
            }
            setFullScreen={(value: any) =>
              setFullScreen((prev) => ({ ...prev, isWIVFullScreen: value }))
            }
            isFullScreen={fullScreen.isWIVFullScreen}
            setFullScreenImage={(image: any) =>
              setFullScreen((prev) => ({
                ...prev,
                WIVfullScreenImage: image,
              }))
            }
          />
        )}

        {fullScreen.isHandoffFormFullScreen && (
          <HandoffForm
            color={style.color}
            threadId={threadId}
            assistantId={assistant.id}
            setMessages={setMessages}
            setIsHandoffFormFullScreen={(value: any) =>
              setFullScreen((prev) => ({
                ...prev,
                isHandoffFormFullScreen: value,
              }))
            }
            setShowButton={setShowHandoffButton}
            language={language}
          />
        )}

        {!isAnyFullScreen && (
          <>
            <ChatHeader
              style={style}
              isEditor={isEditor}
              toggleChatWidget={toggleChatWidget}
              cleanCache={cleanCache}
              language={language}
            />
            <ChatMessages
              messages={messages}
              style={style}
              isMobile={isMobile}
              assistantChatConfiguration={assistantChatConfiguration}
              welcomeMessageTime={welcomeMessageTime}
              isMessageLoading={isMessageLoading}
              toolName={toolName}
              setFullScreen={setFullScreen}
              handleShortcutClick={handleShortcutClick}
              showHandoffButton={showHandoffButton}
              language={language}
              chatMessagesRef={chatMessagesRef}
              onKeywordClick={handleKeywordClick}
              keywords={keywords}
            />
            <ChatInput
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleUserMessage={handleUserMessage}
              style={style}
              isEditor={isEditor}
              isMessageLoading={isMessageLoading}
              language={language}
              chatContainerRef={chatContainerRef}
              isMobile={isMobile}
            />
          </>
        )}
      </div>
    </>
  );
};
