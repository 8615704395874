const constants = {
  DIMENSIONS: {
    MOBILE: {
      CLOSED: {
        WIDTH: 60,
        WIDTH_STRING: "60px",
        HEIGHT: 60,
        HEIGHT_STRING: "60px",
      },
      OPEN: {
        WIDTH_STRING: "100%",
        HEIGHT_STRING: "100%",
      },
    },
    DESKTOP: {
      CLOSED: {
        WIDTH: 360,
        WIDTH_STRING: "250px",
        HEIGHT: 275,
        HEIGHT_STRING: "275px",
      },
      OPEN: {
        WIDTH: 400,
        WIDTH_STRING: "400px",
        HEIGHT: 700,
        HEIGHT_STRING: "700px",
      },
    },
  },
  FEATURE_FLAGS: {
    SLIDER: true,
    TOOLTIP: true,
  },
};

export default constants;
