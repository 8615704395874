import { useState, useEffect } from "react";
import ImageSlideShow from "../../ImageSlideShow";

interface ImageTemplateProps {
  sliderContext: any;
  closeSlider: () => void;
}

export default function ListViewWithQuoteTemplate({
  sliderContext,
  closeSlider,
}: ImageTemplateProps) {
  const [imageIndex, setImageIndex] = useState(0);
  const [roomIndex, setRoomIndex] = useState(0);

  const [roomTransitionFade, setRoomTransitionFade] = useState(true);

  useEffect(() => {
    setRoomTransitionFade(false); // Fade out
    const timeout = setTimeout(() => setRoomTransitionFade(true), 100); // Fade in after a short delay
    return () => clearTimeout(timeout);
  }, [roomIndex]);

  const findRoomIndex = (roomTitle: string | null) => {
    setImageIndex(0);
    if (!roomTitle) return 0;

    const index = sliderContext.template.rooms.findIndex(
      (room: any) => room.title.toLowerCase() === roomTitle
    );

    return index !== -1 ? index : 0;
  };

  /* eslint-disable */
  useEffect(() => {
    const newIndex = findRoomIndex(sliderContext.selectedKeyword);
    if (newIndex !== roomIndex) {
      setRoomIndex(newIndex);
    }
  }, [sliderContext.selectedKeyword]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        overflowY: "hidden",
      }}
    >
      <button className="slider-minimize-button" onClick={() => closeSlider()}>
        <i className="fa-solid fa-minus"></i>
      </button>

      <div style={{ position: "relative", height: "60%", overflow: "hidden" }}>
        {sliderContext?.template?.rooms &&
          sliderContext.template.rooms[roomIndex] &&
          sliderContext.template.rooms[roomIndex]?.images?.images?.length >
            0 && (
            <ImageSlideShow
              images={sliderContext.template.rooms[roomIndex].images.images}
              title={sliderContext.template.rooms[roomIndex].title}
              roomTransitionFade={roomTransitionFade}
              imageIndex={imageIndex}
              setImageIndex={setImageIndex}
            />
          )}
      </div>

      <div
        style={{
          height: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          key={roomIndex}
          style={{
            fontSize: "15px",
            margin: "1rem",
            textAlign: "left",
            opacity: roomTransitionFade ? 1 : 0, // Ensure fade only applies when selectedRoom changes
            transition: "opacity 0.5s ease-in-out", // Smooth fade effect
          }}
        >
          {sliderContext.template.rooms[roomIndex]?.description}
        </div>

        <div style={{ marginBottom: "2rem", textAlign: "center" }}>
          <a
            className="slider-cta-button"
            rel="noreferrer"
            href={sliderContext.template.url}
            target="_blank"
          >
            Prenota ora
          </a>
        </div>
      </div>
    </div>
  );
}
