export class KeywordHandler {
  static returnOrderedKeywords = (template: any, keywords: any) => {
    let templateKeywords = keywords;

    switch (template.type) {
      case "quote":
        template.rooms.forEach((room: any) => {
          if (room.title) {
            let k = room.title.toLowerCase();

            if (!templateKeywords.includes(k)) {
              templateKeywords.push(room.title.toLowerCase());
            }

            templateKeywords = templateKeywords.sort(
              (a: any, b: any) => b.length - a.length
            );
          }
        });
        break;
    }

    return templateKeywords;
  };
}
