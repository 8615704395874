import localization from "../../../assets/localization";

interface ChatInputProps {
  inputMessage: string;
  setInputMessage: (value: string) => void;
  handleUserMessage: () => void;
  style: any;
  isEditor: boolean;
  isMessageLoading: boolean;
  language: string;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

export const ChatInput = ({
  inputMessage,
  setInputMessage,
  handleUserMessage,
  style,
  isEditor,
  isMessageLoading,
  language,
  chatContainerRef,
  isMobile,
}: ChatInputProps) => (
  <div className="widget-chat-footer" style={{ backgroundColor: style.color }}>
    <div className="widget-chat-input-container border border-grey-400">
      <textarea
        className="widget-chat-input"
        disabled={isEditor}
        style={{ fontFamily: "open sans, sans-serif", fontSize: "15px" }}
        placeholder={localization.get(language).type_a_message}
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        onClick={() => {
          if (isMobile)
            chatContainerRef.current?.scrollTo(
              0,
              chatContainerRef.current.scrollHeight
            );
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            handleUserMessage();
          }
        }}
      />
      <button
        disabled={isEditor || isMessageLoading}
        className="widget-send-button flex-none m-0 p-0 text-white"
        style={{ backgroundColor: style.color }}
        onClick={handleUserMessage}
      >
        <i
          className="fa-regular fa-paper-plane"
          style={{ color: "whitesmoke", fontSize: "16px" }}
        ></i>
      </button>
    </div>
    <div className="powered-by">
      <span style={{ fontSize: "10px", color: "white", marginRight: "3px" }}>
        {localization.get(language).powered_by}
      </span>
      <a
        href="https://plutoniosolutions.com/"
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "12px",
          color: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        PlutoniosSolutions
        <img
          src="https://plutoniosolutions.com/assets/img/SmallCircle.webp"
          alt="Plutonios Solutions Logo"
          className="inline-block mr-1"
          style={{
            width: "10px",
            marginLeft: "2px",
            verticalAlign: "baseline",
            border: "1px solid white",
            borderRadius: "50%",
          }}
        />
      </a>
    </div>
  </div>
);
