import ChatService from "../../../front-services/ChatService";
import ChatCache from "../../../utils/ChatCache";
import Utility from "./Utility";

const chatCache = new ChatCache();

export default class MessageHandler {
  chatService: ChatService;

  constructor() {
    this.chatService = new ChatService();
  }
  handleUserMessageWithoutStream = async (
    setIsMessageLoading: any,
    assistant: any,
    threadId: any,
    setThreadId: any,
    inputMessage: any,
    setMessages: any,
    language: any
  ) => {
    let botResponse = await this.sendMessageWithoutStream(
      inputMessage,
      setIsMessageLoading,
      assistant,
      threadId,
      setThreadId,
      language
    );

    if (botResponse) {
      let message = {
        type: "bot",
        content: botResponse.messageReply,
        metadata: botResponse.metadata,
        time: Utility.getTime(),
      };

      setMessages((prevMessages: any) => [...prevMessages, message]);
      chatCache.addMessage(message, threadId, assistant.id);
    }
  };

  sendMessageWithoutStream = async (
    userMessage: any,
    setIsMessageLoading: any,
    assistant: any,
    threadId: any,
    setThreadId: any,
    language: any
  ) => {
    setIsMessageLoading(true);

    const response = await this.chatService.sendMessage(
      userMessage,
      assistant.id,
      threadId,
      language
    );

    if (response.ok) {
      const dataRaw = await response.json();

      const data: any = {
        messageReply: dataRaw.response,
        threadId: dataRaw.thread_id,
        assistantId: assistant.id,
        metadata: dataRaw.metadata,
      };

      setIsMessageLoading(false);
      setThreadId(data.threadId);
      return data;
    }
    setIsMessageLoading(false);

    return "Sorry, something went wrong. Please try again later.";
  };

  handleUserMessageWithStream = async (
    inputMessage: any,
    setMessages: any,
    setIsMessageLoading: any,
    setThreadId: any,
    assistant: any,
    threadId: any,
    setToolName: any,
    openSlider: any,
    language: any
  ) => {
    await this.sendMessageWithStream(
      inputMessage,
      assistant,
      threadId,
      setMessages,
      setIsMessageLoading,
      setThreadId,
      setToolName,
      openSlider,
      language
    );
  };

  sendMessageWithStream = async (
    inputMessage: any,
    assistant: any,
    threadId: any,
    setMessages: any,
    setIsMessageLoading: any,
    setThreadId: any,
    setToolName: any,
    openSlider: any,
    language: any
  ) => {
    return new Promise((resolve, reject) => {
      const eventSource = this.chatService.getStreamEventSource(
        inputMessage,
        assistant.id,
        threadId,
        language
      );

      let botResponse = {
        messageReply: "",
        metadata: null,
      };

      let id = Utility.generateUniqueId();

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          let eventType = data.event.type;

          if (eventType === "event.stream.chunk") {
            setIsMessageLoading(false);
            setThreadId(data.thread_id);
            botResponse.messageReply += data.event.content;
            setMessages((prevMessages: any) => {
              const updatedMessages = [...prevMessages];
              const lastIndex = updatedMessages.length - 1;
              const lastMessage = updatedMessages[lastIndex];

              if (lastMessage && lastMessage.type === "bot") {
                updatedMessages[lastIndex] = {
                  ...lastMessage,
                  content: botResponse.messageReply,
                };
              } else {
                const newMessage = {
                  id: id,
                  type: "bot",
                  content: botResponse.messageReply,
                  metadata: botResponse.metadata,
                  time: Utility.getTime(),
                };
                updatedMessages.push(newMessage);
              }
              return updatedMessages;
            });
          } else if (eventType === "event.tool.start") {
            let tool_name = data.event.tool_name;
            setToolName(tool_name);
          } else if (eventType === "event.stream.reply.completed") {
            if (data.event.response_template)
              openSlider(JSON.parse(data.event.response_template) || null);

            let metadata = data.metadata;
            setMessages((prevMessages: any) => {
              const updatedMessages = [...prevMessages];
              const lastIndex = updatedMessages.length - 1;
              const lastMessage = updatedMessages[lastIndex];

              if (lastMessage && lastMessage.type === "bot") {
                updatedMessages[lastIndex] = {
                  ...lastMessage,
                  metadata: metadata,
                };
                chatCache.addMessage(lastMessage, data.thread_id, assistant.id);
              }
              return updatedMessages;
            });

            setToolName(null);
            //eventSource.close();
            resolve(botResponse);
          } else if (eventType === "event.redirected.waiting") {
            setIsMessageLoading(false);
            setThreadId(data.thread_id);
            botResponse.messageReply += data.event.message;
            setMessages((prevMessages: any) => {
              const newMessage = {
                id: id,
                type: "bot",
                content: botResponse.messageReply,
                metadata: botResponse.metadata,
                time: Utility.getTime(),
              };

              return [...prevMessages, newMessage];
            });
          } else if (eventType === "event.stream.error") {
            botResponse.messageReply += data.event.error;
            setMessages((prevMessages: any) => {
              const updatedMessages = [...prevMessages];

              const newMessage = {
                id: id,
                type: "bot",
                content: botResponse.messageReply,
                metadata: botResponse.metadata,
                time: Utility.getTime(),
              };
              updatedMessages.push(newMessage);

              return updatedMessages;
            });
            setIsMessageLoading(false);
            eventSource.close();
            resolve(data.response);
          }
        } catch (error) {
          console.error("Error in eventSource.onmessage:", error);
        }
      };

      eventSource.onerror = (err) => {
        // evitiamo di sporcare i log
        //console.error("EventSource failed:", err);
        eventSource.close();
      };
    });
  };
}
