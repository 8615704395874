import ReactMarkdown from "react-markdown";
import Colors from "../../../utils/Colors";
import WideImageViewer from "../components/WideImageViewer";
import HandoffButton from "../components/Handoff/HandoffButton";
import constants from "../../../utils/Constants";
import localization from "../../../assets/localization";
import React from "react";
import KeywordRenderer from "../components/slider/Keyword/KeywordRenderer";

interface ChatMessagesProps {
  messages: any[];
  style: any;
  isMobile: boolean;
  assistantChatConfiguration: any;
  welcomeMessageTime: string;
  isMessageLoading: boolean;
  toolName: string | null;
  setFullScreen: (state: any) => void;
  handleShortcutClick: (message: string) => void;
  showHandoffButton: boolean;
  language: string;
  chatMessagesRef: React.RefObject<HTMLDivElement>;
  onKeywordClick: (keyword: string) => void;
  keywords: string[];
}

export const ChatMessages = ({
  messages,
  style,
  isMobile,
  assistantChatConfiguration,
  welcomeMessageTime,
  isMessageLoading,
  toolName,
  setFullScreen,
  handleShortcutClick,
  showHandoffButton,
  language,
  chatMessagesRef,
  onKeywordClick,
  keywords,
}: ChatMessagesProps) => {
  const CustomLinkRenderer = ({ href, children }: any) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const redirectToWhatsapp = (phone: string) => {
    window.open(`https://api.whatsapp.com/send?phone=${phone}`, "_blank");
  };

  const getToolAction = (tool: string) =>
    localization.get(language).tools_actions[tool];

  return (
    <div className="widget-chat-messages" ref={chatMessagesRef}>
      {assistantChatConfiguration.welcomeMessage && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginBottom: "10px",
              backgroundColor: Colors.lightenHexColor(style.color, 5),
            }}
            className="widget-chatbot-message"
          >
            <ReactMarkdown>
              {assistantChatConfiguration.welcomeMessage}
            </ReactMarkdown>
            {assistantChatConfiguration.shortcutButtons && (
              <div className="shortcut-buttons">
                {assistantChatConfiguration.shortcutButtons.map(
                  (sbutton: any, index: number) => (
                    <button
                      className="shortcut-button"
                      style={{ color: Colors.lightenHexColor(style.color, 10) }}
                      key={index}
                      onClick={() => handleShortcutClick(sbutton.userMessage)}
                    >
                      {sbutton.label}
                    </button>
                  )
                )}
                {assistantChatConfiguration?.phoneNumber && (
                  <button
                    className="shortcut-button"
                    style={{
                      color: Colors.lightenHexColor(style.color, 10),
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      redirectToWhatsapp(assistantChatConfiguration.phoneNumber)
                    }
                  >
                    <i
                      className="fab fa-whatsapp"
                      style={{ fontSize: "1rem" }}
                    ></i>{" "}
                    Whatsapp
                  </button>
                )}
              </div>
            )}
            <p className="message-time">{welcomeMessageTime}</p>
          </div>
        </div>
      )}
      {messages.map((message, index) => (
        <div
          key={index}
          style={{
            marginBottom: "10px",
            backgroundColor:
              message.type !== "user"
                ? Colors.lightenHexColor(style.color, 5)
                : Colors.lightenHexColor(style.color, 85),
          }}
          className={
            message.type === "user"
              ? "widget-user-message"
              : "widget-chatbot-message"
          }
        >
          {index === messages.length - 1 ? (
            <ReactMarkdown
              components={{
                a: CustomLinkRenderer,
                p: (props) => (
                  <KeywordRenderer
                    {...props}
                    wrapper="p"
                    {...{ keywords, onKeywordClick }}
                  />
                ),
                li: (props) => (
                  <KeywordRenderer
                    {...props}
                    wrapper="li"
                    {...{ keywords, onKeywordClick }}
                  />
                ),
              }}
            >
              {message.content}
            </ReactMarkdown>
          ) : (
            <ReactMarkdown
              components={{
                a: CustomLinkRenderer,
              }}
            >
              {message.content}
            </ReactMarkdown>
          )}
          {message.metadata?.map((meta: any, metaIndex: number) => {
            switch (meta.type) {
              case "image":
                return meta.value.startsWith("https://i.ibb.co/") ? (
                  <div className="message-attachment">
                    <WideImageViewer
                      image={meta.value}
                      maxHeight={
                        isMobile
                          ? constants.DIMENSIONS.MOBILE.OPEN.HEIGHT_STRING
                          : constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING
                      }
                      maxWidth={
                        isMobile
                          ? constants.DIMENSIONS.MOBILE.OPEN.WIDTH_STRING
                          : constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING
                      }
                      setFullScreen={(value: any) =>
                        setFullScreen((prev: any) => ({
                          ...prev,
                          isWIVFullScreen: value,
                        }))
                      }
                      isFullScreen={false} // Controlled by parent
                      setFullScreenImage={(image: any) =>
                        setFullScreen((prev: any) => ({
                          ...prev,
                          WIVfullScreenImage: image,
                        }))
                      }
                    />
                  </div>
                ) : (
                  <img
                    key={metaIndex}
                    src={meta.value}
                    alt="attachment"
                    className="message-attachment"
                  />
                );
              case "human_handoff_form":
                return (
                  <HandoffButton
                    setHandoffFormFullscreen={(value: any) =>
                      setFullScreen((prev: any) => ({
                        ...prev,
                        isHandoffFormFullScreen: value,
                      }))
                    }
                    show={showHandoffButton}
                    label={localization.get(language).speak_with_operator}
                    style={style}
                  />
                );
              default:
                return null;
            }
          })}
          <p className="message-time">{message.time}</p>
        </div>
      ))}
      {isMessageLoading && (
        <div
          className="widget-chatbot-message"
          style={{ backgroundColor: Colors.lightenHexColor(style.color, 5) }}
        >
          <span
            className="loader-container"
            style={{ backgroundColor: Colors.lightenHexColor(style.color, 5) }}
          >
            {toolName && getToolAction(toolName)}
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </span>
        </div>
      )}
    </div>
  );
};
