export default function Keyword({
  keywordMatch,
  onKeywordClick,
}: {
  keywordMatch: string;
  onKeywordClick: (keywordMatch: string) => void;
}) {
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        onKeywordClick(keywordMatch);
      }}
      style={{
        color: "white",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: "bold",
        position: "relative",
      }}
    >
      {keywordMatch}
    </span>
  );
}
