import Colors from "../../../utils/Colors";
import localization from "../../../assets/localization";

interface ChatHeaderProps {
  style: any;
  isEditor: boolean;
  toggleChatWidget: () => void;
  cleanCache: () => void;
  language: string;
}

export const ChatHeader = ({
  style,
  isEditor,
  toggleChatWidget,
  cleanCache,
  language,
}: ChatHeaderProps) => (
  <div className="widget-header" style={{ backgroundColor: style.color, borderColor: style.color }}>
    <div style={{ display: "flex" }}>
      <img src={style.fileUrl} style={{ borderRadius: "50%" }} alt="Assistant Icon" className="header-icon inline-block" />
      <div className="header-title-description">
        <span className="text-lg font-semibold ml-2 widget-header-title"><b>{style.name}</b></span>
        <span className="header-description">{localization.get(language).replies_in_realtime}</span>
      </div>
    </div>
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <button
        className="trash-button"
        style={{ backgroundColor: Colors.lightenHexColor(style.color, 35) }}
        onClick={cleanCache}
        disabled={isEditor}
      >
        <i className="fa-solid fa-trash"></i>
      </button>
      <button
        className="minimize-button"
        style={{ backgroundColor: Colors.lightenHexColor(style.color, 35) }}
        onClick={toggleChatWidget}
        disabled={isEditor}
      >
        <i className="fa-solid fa-x"></i>
      </button>
    </div>
  </div>
);