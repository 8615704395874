import constants from "../../../../utils/Constants";
import ListViewWithQuoteTemplate from "./templates/ListViewWithQuoteTemplate";

interface SliderProps {
  isMobile: boolean;
  sliderContext: any;
  closeSlider: () => void;
  style: any;
}

export const Slider = ({
  sliderContext,
  closeSlider,
  isMobile,
  style,
}: SliderProps) => {
  let content = null;

  switch (sliderContext?.template?.type) {
    case "quote":
      content = (
        <ListViewWithQuoteTemplate
          sliderContext={sliderContext}
          closeSlider={closeSlider}
        />
      );
      break;
  }

  return isMobile ? (
    <div
      style={{
        position: "fixed",
        bottom: sliderContext.isVisible ? "0%" : "-100%",
        left: "0",
        width: "100%",
        height: "90%",
        backgroundColor: "#fff",
        boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        transition: "bottom 0.4s ease-in-out, opacity 0.4s ease-in-out",
        opacity: sliderContext.isVisible ? "1" : "0",
        zIndex: "1000",
      }}
    >
      <div
        style={{
          height: "100%",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          overflowY: "auto",
        }}
      >
        {content}
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "fixed",
        right: !sliderContext.isVisible ? "20px" : "420px",
        width: constants.DIMENSIONS.DESKTOP.OPEN.WIDTH_STRING,
        bottom: isMobile ? "0px" : "20px",
        height: constants.DIMENSIONS.DESKTOP.OPEN.HEIGHT_STRING,
        zIndex: -9999,
        border: `1px solid ${style.color}`,
        boxShadow: `0 2px 6px -1px ${style.color}, 0 2px 2px -1px ${style.color}`,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        borderRadius: "30px 0px 0px 30px",
        overflow: "hidden",
        opacity: sliderContext.isVisible ? "1" : "0",
        transition:
          "right 0.4s ease-in-out, opacity 0.3s ease-in-out 0.1s, border 0.3s ease-in-out",
      }}
    >
      {content}
    </div>
  );
};
