import React, { ReactNode } from "react";
import reactStringReplace from "react-string-replace";
import Keyword from "./Keyword";

interface KeywordRendererProps {
  children?: ReactNode;
  keywords: string[];
  wrapper: string;
  onKeywordClick: any;
}

export default function KeywordRenderer({
  onKeywordClick,
  children,
  keywords,
  wrapper,
  ...props
}: KeywordRendererProps & any) {
  const highlightKeywords = (node: ReactNode): ReactNode => {
    if (typeof node === "string") {
      return keywords.reduce((acc: any, keyword: any) => {
        const regex = new RegExp(
          `(${keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")})`,
          "gi"
        );
        return reactStringReplace(acc, regex, (match: any, i: any) => (
          <Keyword
            key={`${keyword}-${i}`}
            keywordMatch={match}
            onKeywordClick={onKeywordClick}
          />
        ));
      }, node);
    }

    // questo è per evita di intaccare altri comoponenti
    if (React.isValidElement(node)) {
      return React.cloneElement(node, {
        ...(node.props.children && {
          children: React.Children.map(node.props.children, highlightKeywords),
        }),
      });
    }

    if (Array.isArray(node)) {
      return node.map((child, i) => (
        <React.Fragment key={i}>{highlightKeywords(child)}</React.Fragment>
      ));
    }

    return node;
  };

  return React.createElement(wrapper, props, highlightKeywords(children));
}
