import { useEffect } from "react";

const INTERVAL_TIME = 10000;

export default function ImageSlideShow({
  images,
  title,
  imageIndex,
  setImageIndex,
  roomTransitionFade,
}: any) {
  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex: any) => (prevIndex + 1) % images.length);
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [images.length, setImageIndex]);

  const goToPrevious = () => {
    setImageIndex((prevIndex: any) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setImageIndex((prevIndex: any) => (prevIndex + 1) % images.length);
  };

  const goToSlide = (index: number) => {
    setImageIndex(index);
  };
  return (
    <>
      {/* Image */}
      {images &&
        images.map((image: any, index: any) => (
          <img
            key={index}
            src={image.image_url}
            alt={image.description}
            className="image-slideshow-item"
            style={{
              opacity: index === imageIndex ? 1 : 0,
              transition: "opacity 0.8s ease-in-out",
            }}
          />
        ))}

      {/* Navigation buttons */}
      <button
        onClick={goToPrevious}
        className="image-slideshow-navigation-button"
        style={{
          left: "5px",
        }}
      >
        ❮
      </button>
      <button
        onClick={goToNext}
        className="image-slideshow-navigation-button"
        style={{
          right: "5px",
        }}
      >
        ❯
      </button>

      {/* Dots navigation */}
      <div className="image-slideshow-dot-container">
        {images.map((_: any, index: any) => (
          <div
            key={index}
            onClick={() => goToSlide(index)}
            className="image-slideshow-dot"
            style={{
              backgroundColor:
                index === imageIndex ? "var(--primary-color)" : "#fff",
              opacity: index === imageIndex ? 1 : 0.5,
            }}
          />
        ))}
      </div>

      <div
        className="image-slideshow-title-box"
        style={{
          opacity: roomTransitionFade ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <div className="image-slideshow-title">{title}</div>
        <div className="image-slideshow-subtitle">Disponibile</div>
      </div>
    </>
  );
}
