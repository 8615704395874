import constants from "../../../utils/Constants";
import ChatTooltip from "../components/ChatTooltip";

interface ChatButtonProps {
  isOpen: boolean;
  toggleChatWidget: () => void;
  style: any;
  isMobile: boolean;
  language: string;
}

export const ChatButton = ({
  isOpen,
  toggleChatWidget,
  style,
  isMobile,
  language,
}: ChatButtonProps) => (
  <div
    className="widget-chat-button p-1 w-14 h-14 rounded-full shadow-lg flex items-center justify-center text-white text-3xl focus:outline-none hover:bg-blue-600 transition-colors duration-200 ease-in-out relative"
    style={{
      backgroundColor: style.color,
      boxShadow: `0 4px 6px -1px ${style.color}, 0 2px 4px -1px ${style.color}`,
      opacity: !isOpen ? "1" : "0",
      transition: "opacity 0.15s ease-in-out",
      position: "fixed",
      bottom: isMobile ? "20px" : "50px",
      right: isMobile ? "20px" : "50px",
      width: isMobile ? "60px" : "80px",
      height: isMobile ? "60px" : "80px",
      color: "#fff",
      border: "none",
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: "60px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    onClick={toggleChatWidget}
  >
    <i className="fas fa-comments chat-icon" style={{ fontSize: isMobile ? "25px" : "38px" }}></i>
    {constants.FEATURE_FLAGS.TOOLTIP && <ChatTooltip isMobile={isMobile} language={language} />}
    <div
      className="pulse-effect"
      style={{
        position: "absolute",
        bottom: "0px", 
        left: "0px",
        width: isMobile ? "12px" : "15px",
        height: isMobile ? "12px" : "15px",
        borderRadius: "50%",
        backgroundColor: "rgba(38, 178, 11)", 
        border: "2px solid white",
        boxShadow: "0 0 0 0 rgba(38, 178, 11, 0.4)",
        animation: "pulse 2s infinite",
      }}
    />
  </div>
);